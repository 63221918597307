<template>
  <div class="p-grid">
    <div :style="expandSidebarDialog ? 'width:70rem;' : 'width:20rem;'"
         style="margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff"
    >
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0">
          <Button
              v-tooltip="expandSidebarDialog ? _t('label_List_view') : _t('label_Table_view')"
              :icon="expandSidebarDialog ? 'mdi mdi-table-arrow-left' : 'mdi mdi-table-arrow-right'"
              class="p-button-rounded p-button-icon-only p-button-text"
              style="float: right;"
              @click="expandSidebarDialog = !expandSidebarDialog"
          />
          <Button v-if="!isEditable" v-tooltip.bottom="'Add participant group'" class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-plus" style="float: right;"
                  @click="initNewParticipantGroupDialog()"/>
          <ChildFilter ref="cf" v-model="participantGroupsList" :orgId="orgId" style="float: right;"/>

          <strong>{{ _t('menu.participant_groups') }}</strong>
        </h3>

        <MultiSelectableBreadcrumb ref="breadcrumbData" :tree-params="treeParams" single-selection
                                   @change="setOrgId"/>
      </div>
      <div>
          <span :style="expandSidebarDialog ? 'width:' + ' calc(100% - 3rem)' : 'width:' + ' calc(100% - 3rem);'"
                class="p-input-icon-right p-m-3">
            <i class="pi pi-search" style="font-size: 12px;"/>
            <InputText v-model="participantFilterValue" style="width:100%;" type="text"/>
          </span>
      </div>
      <DataTable
          ref="tree"
          v-model:selection="selectedParticipantGroupItem"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="currentPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="participantTableRows"
          :scrollable="true"
          :totalRecords="totalRecords"
          :value="participantGroupsList"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
          @row-click="this.selectParticipantGroup($event.data.id);"
      >
        <Column :expander="true"
                :header="_t('Name')"
                :style="'width: ' + (100 / alarmParticipantGroupsValues.length) + '%; word-break: break-all; min-height: 38px;'"
                field="name"
        >
          <template #body="{data}">
                <span class="wrap"
                      style="word-break: break-all; padding-left: 5px;">
              {{ data.name }}
               </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                :header="_t('ORGANIZATION')"
                :hidden="!handleCheckRow('organization')"
                :style="'width: ' + (100 / alarmParticipantGroupsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
                field="organization"
        >
          <template #body="{data}">
              <span
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
              >
              {{ data.orgName }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog" :header="_t('label_participants')"
                :hidden="!handleCheckRow('participants')"
                :style="'width: ' + (100 / alarmParticipantGroupsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
                field="organization"
        >
          <template #body="{data}">
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <span v-for="(item, idx) in data.innerMembers" :key="idx + item.id"
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
            >
              {{ item }}{{ idx !== data?.innerMembers?.length - 1 ? '; ' : '' }}
              </span>
            </div>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog" :header="_t('label_Alarms')"
                :hidden="!handleCheckRow('alarms')"
                :style="'width: ' + (100 / alarmParticipantGroupsValues.length)+ '%; word-break: break-all;min-height: 38px;'"
                field="organization"
        >
          <template #body="{data}">
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <span v-for="(item, idx) in data.linkedAlarmPlans" :key="idx + item.id"
                  style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
            >
              {{ item }}{{ idx !== data?.linkedAlarmPlans?.length - 1 ? '; ' : '' }}
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <transition name="slide-fade">
      <Card v-show="selectedParticipantGroup.id !== null" class="p-col" style="overflow-x: scroll;">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0"><strong>{{ selectedParticipantGroup.name }}</strong></h3>
            </div>
            <div>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')" class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="cancelChanges()"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')" class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check"
                      @click="saveParticipantGroup()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')" class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil"
                      @click="editParticipantGroup()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')" class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      @click="deleteParticipantGroupDialog = true"/>
            </div>
          </div>
        </template>
        <template #content>
          <TabView>
            <TabPanel :header="_t('menu.general')">
              <div class="p-grid">
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_object_name') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipantGroup.name }}</strong></p>
                  <span v-else>
                  <InputText v-model="selectedParticipantGroup.name" maxlength="45" style="width:70%; margin-top:0;"
                             type="text"/>
                  <i v-tooltip.bottom="_t('label_groups_hint')" class="pi pi-question-circle"
                     style="float: right;"></i>
                </span>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_description') }}</p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedParticipantGroup.description ?? '...' }}</strong></p>
                  <span v-else>
                  <InputText v-model="selectedParticipantGroup.description" maxlength="255" style="width:70%; margin-top:0;"
                             type="text"/><br/>
                </span>

                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <OrganizationLink ref="orgLink" v-model="selectedParticipantGroup"
                                    :editMode="isEditable" modelType="PARTICIPANT_GROUP"/>
                </div>
              </div>
              <TabView @tab-click="newCallGroupDialog=isEditable">
                <TabPanel :header="_t('label_Target_groups')">
                  <div v-if="selectedParticipantGroup.callGroups && selectedParticipantGroup.callGroups.length > 0"
                       class="p-grid">
                    <Card v-for="(callGroup, index) in selectedParticipantGroup.callGroups"
                          :key="callGroup"
                          class="p-mr-2 p-mb-2"
                          style="display:inline-block; width:20rem; font-size: 12px;"
                    >
                      <template #content>
                        <div class="p-grid">
                          <div class="p-col-9">
                            <div v-if="isEditable" class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi-pencil"></i>
                          </span>
                              <InputText v-model="callGroup.name" :placeholder="_t('label_group_name')" class="p-m-0"
                                         maxlength="45" style="width:100%;"/>
                            </div>
                            <p v-else class="color-green"><strong>{{
                                callGroup.name && callGroup.name.length ? callGroup.name : '[No title]'
                              }}</strong></p>
                          </div>
                          <div class="p-col-3 p-text-right">
                            <Button v-if="isEditable" class="p-button-danger" @click="initCallGroupDeleteDialog(index)">
                              <i class="pi pi-trash"></i>
                            </Button>
                          </div>
                          <div class="p-col-8" style="vertical-align: middle;">
                            <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>
                          </div>
                          <div class="p-col-4 p-text-right">
                      <span v-if="isEditable" class="p-buttonset">
                        <Button v-tooltip.bottom="_t('label_Edit_participants')"
                                class="p-button-success p-button-icon-only p-button-text"
                                @click="initCallGroupEditDialog(callGroup)">
                          <i class="pi pi-plus-circle"></i>
                        </Button>
                        <Button
                            v-tooltip.bottom="_t('label_Edit_participants')"
                            :disabled="!selectedCallGroupMembers[callGroup.id] || selectedCallGroupMembers[callGroup.id].length < 1"
                            class="p-button-danger p-button-icon-only p-button-text" @click="removeCallGroupMembers(callGroup)">
                          <i class="pi pi-minus-circle"></i>
                        </Button>
                      </span>
                          </div>
                          <div class="p-col-12">
                            <DataTable
                                v-model:selection="selectedCallGroupMembers[callGroup.id]"
                                :disabled="!isEditable" :scrollable="true"
                                :value="callGroup.members"
                                class="p-m-0 condensed"
                                dataKey="id"
                                scrollHeight="20rem"
                                selectionMode="multiple"
                                style="width:100%; font-size: 12px;"
                                @row-reorder="reorderCallGroupMembers(callGroup, $event.value)"
                                @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"
                            >
                              <Column :rowReorder="isEditable" style="flex: 0 0 2rem"/>
                              <Column field="title"
                              >
                                <template #body="slotProps">
                                  <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                  <i v-else class="pi pi-users p-mr-1"></i>
                                  {{ slotProps.data.title }}
                                </template>
                              </Column>
                              <Column :selectionMode="isEditable ? 'multiple' : ''" style="flex: 0 0 2rem"/>
                            </DataTable>
                          </div>
                        </div>
                      </template>
                    </Card>
                    <div v-if="isEditable" class="p-ml-2 p-mt-2" style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus" @click="newCallGroupDialog = true"/>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{ _t('label_No_target_groups') }}</p>
                    <div v-if="isEditable" class="p-ml-2 p-mt-2" style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus" @click="newCallGroupDialog = true"/>
                    </div>
                  </div>
                </TabPanel>
              </TabView>

            </TabPanel>
            <TabPanel :header="_t('label_Membership')">
              <h3 class="subhead p-mt-4">{{ _t('ALARMPLANS') }}</h3>
              <div v-if="alarmPlans.length" class="p-grid">
                <div v-for="(alarmPlan, i) in alarmPlans" :key="alarmPlan" class="p-col-3">
                  <Button :label="alarmPlan.name" class="p-button-link text" @click="toAlarmPlan(alarmPlan.id)"/>
                  <p class="p-mt-2">
                    {{ _t('label_object_name') }}: {{ alarmPlan.name }} <br/>
                    {{ _t('label_organization') }}:
                    <OrganizationLink v-model="alarmPlans[i]" :editMode="false"/>
                  </p>
                </div>
              </div>
              <p v-else>{{ _t('label_not_assigned_to_plan') }}</p>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </transition>
  </div>
  <Dialog v-model:visible="newParticipantGroupDialog" :style="{width: '450px'}">
    <template #header>
      <h3>{{ _t('label_Add_new_participant_group') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-pencil"></i>
        </span>
          <InputText
              id="newParticipantGroupName"
              v-model="newParticipantGroup.name"
              :class="newParticipantGroup.errors ? 'p-invalid p-m-0' : 'p-m-0'"
              :placeholder="_t('label_object_name')"
              maxlength="45"
          />
        </div>
        <small v-if="newParticipantGroup.errors" class="p-error">{{ newParticipantGroup.errors[0] }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <InputText
              v-model="newParticipantGroup.description"
              :placeholder="_t('label_description')"
              class="p-m-0"
              maxlength="255"
          />
        </div>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <OrganizationLink v-model="newParticipantGroup" :editMode="true" :required-field="true"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="newParticipantGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createParticipantGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="newCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Add_new_target_group') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText
          v-model="newCallGroup.name"
          class="p-m-0"
          maxlength="45"
          placeholder="Title"
      />
    </div>
    <div class="p-inputgroup p-mt-2">
      <p>{{ _t('label_organization') }}: <strong>{{ selectedParticipantGroup.orgName }}</strong></p>
    </div>
    <p>{{ _t('PARTICIPANT_GROUP') }}: <strong>{{ selectedParticipantGroup.name }}</strong></p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Update_participants') }}</h3>
    </template>
    <p>{{ _t('label_Update_participants_for_target_group') }} <strong class="color-green">{{
        originalCallGroup.name
      }}</strong></p>
    <ParticipantSelector v-model="editCallGroup.members" scrollHeight="40vh" style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Delete_participant_group') }}</h3>
    </template>
    <p>
      {{ _t('label_Confirm_deletion_participant_group') }}
      <strong>{{ selectedParticipantGroup.callGroups[deleteCallGroupIndex].name ?? '[No title]' }}</strong>
    </p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteParticipantGroupDialog">
    <template #header>
      <h3>{{ _t('label_Delete_participant_group') }}</h3>
    </template>
    <p>{{ _t('label_Confirm_deletion_participant_group') }}<strong>{{ selectedParticipantGroup.name }}</strong></p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times"
              @click="deleteParticipantGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteParticipantGroup()"/>
    </template>
  </Dialog>
</template>

<script>
import router from '@/router';
import {getList} from '@/api/list'
import {
  createParticipantGroup,
  deleteParticipantGroup,
  getParticipantGroup,
  getParticipantGroupAlarmPlans,
  updateParticipantGroup
} from "@/api/participantGroup";
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import OrganizationLink from "../../components/ixarma/OrganizationLink";
import ChildFilter from '../../components/ixarma/ChildFilter'
import ParticipantSelector from '../../components/ixarma/ParticipantSelector'
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";

export default {
  name: 'index',
  components: {MultiSelectableBreadcrumb, OrganizationLink, ChildFilter, ParticipantSelector},
  data() {
    return {
      isEditable: false,
      newParticipantGroupDialog: false,
      newParticipantGroup: {
        name: '',
        errors: []
      },
      deleteParticipantGroupDialog: false,
      newCallGroupDialog: false,
      newCallGroup: {},
      editCallGroupDialog: false,
      originalCallGroup: null,
      editCallGroup: {
        members: [],
      },
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      participantFilterValue: '',
      participants: [],
      participantGroups: [],
      participantGroupsList: [],
      changePassDlg: false,
      totalRecords: 100,
      participantTableRows: 100,
      currentPage: 0,
      loading: true,
      dataTabs: false,
      statuses: [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
      ],
      selectedCallGroupMembers: {},
      selectedTreeTableValue: null,
      selectedParticipantGroupItem: null,
      selectedParticipantGroup: {
        id: null,
        name: '',
        description: '',
        login: '',
        personalId: '',
        language: '',
        callGroups: []
      },
      pageSize: ['5', '10', '20', '50'],
      pgFilterValue: '',
      treeParams: '',
      pEx: [],
      orgId: localStorage.getItem('orgId') ?? 1,
      alarmPlans: [],
      expandSidebarDialog: false,
      alarmParticipantGroupsValues: [],
    }
  },
  created() {

  },
  watch: {
    pgFilterValue() {
      this.getParticipantGroupsList()
    },
    selectedParticipantGroupItem(item) {
      this.selectParticipantGroup(item.value)
    },
    participantFilterValue() {
      this.getParticipantGroupsList()
    }
  },
  mounted() {
    this.checkOrgId()
    this.getParticipantGroupsList()
    if (this.$route.params.id) {
      this.selectParticipantGroup(this.$route.params.id)
    }

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'alarmParticipantGroupsItems') this.alarmParticipantGroupsValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key === 'alarmParticipantGroupsItems') this.alarmParticipantGroupsValues = value
            }
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
  },
  methods: {
    setOrgId(id) {
      this.orgId = id
      this.getParticipantGroupsList()
      this.selectedParticipantGroup = {
        id: null,
        name: '',
        description: ''
      }
      this.isEditable = false
    },
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    initNewParticipantGroupDialog() {
      this.newParticipantGroupDialog = true
      this.newParticipantGroup = {
        name: '',
        description: '',
        orgName: this.orgName,
        orgId: this.orgId
      }
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedParticipantGroup.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
    },
    removeCallGroupMembers(callGroup) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
    },
    clearFilter() {
      this.pgFilterValue = ''
    },
    toMember(participantId, participantGroupId) {
      if (this.isEditable) {
        return
      }

      if (participantId) {
        router.push(`/participants/${participantId}`)
        return
      }

      if (participantGroupId) {
        router.push(`/participant-groups/${participantGroupId}`)

      }
    },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.getParticipantGroupsList();
    },
    selectParticipantGroup(id) {
      if (id) {
        getParticipantGroup(id).then((response) => {
          this.selectedParticipantGroup = response.data
          this.getLinkedAlarmPlans()
          this.isEditable = false
        })
      }
    },
    cancelChanges() {
      this.isEditable = false
      if (this.selectedParticipantGroup.id && !this.selectedParticipantGroup.id.startsWith('_')) {
        this.selectParticipantGroup(this.selectedParticipantGroup.id)
      } else {
        this.selectedParticipantGroup.id = null
      }
    },
    getParticipantGroupsList() {
      getList('PARTICIPANT_GROUP', this.currentPage, this.participantFilterValue, this.participantTableRows).then((response) => {
        this.totalRecords = response.data.count
        this.participantGroupsList = response.data.list.map(item => {
          const container = item;
          container.value = item.id;
          return container;
        })
        this.participantGroupsList.sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false
        this.$refs.cf.refresh(this.participantGroupsList)
      })
    },
    editParticipantGroup() {
      this.isEditable = !this.isEditable
    },
    saveParticipantGroup() {
      let data = {}
      if (!this.selectedParticipantGroup.id || this.selectedParticipantGroup.id.startsWith('_')) {
        data = Object.assign({}, this.newParticipantGroup)
      } else {
        data = Object.assign({}, this.selectedParticipantGroup)
      }

      if (!data.name || !data.name.length) {
        this.$root.showMessage('Please, specify participant group name.', 'error')
        return false
      }

      if (data.name.length > 45) {
        this.$root.showMessage('Participant group name should be no longer 45 characters.', 'error')
        return false
      }

      if (data.description && data.description.length > 255) {
        this.$root.showMessage('Participant group description should be no longer 255 characters.', 'error')
        return false
      }

      let hasParticipants, nameEmpty = false
      let nameValid = true
      data.callGroups.forEach(function (group) {
        if (group.id && group.id.startsWith('_')) {
          delete group.id
        }
        if (group.participantGroupId && group.participantGroupId.startsWith('_')) {
          delete group.participantGroupId
        }
        if (group.name === null) {
          nameEmpty = true
        }
        if (group.name !== null && group.name.length > 45) {
          nameValid = false
        }

        group.members.forEach(function (member) {
          hasParticipants = true
          if (member.id && member.id.startsWith('_')) {
            delete member.id
          }
          if (member.participantGroupId && member.participantGroupId.startsWith('_')) {
            member.participantGroupId = null
          }
        })
      })

      if (nameEmpty) {
        this.$root.showMessage('Target group name is required.', 'error')
        return false
      }

      if (!hasParticipants) {
        this.$root.showMessage('Participant group should have at least one member.', 'error')
        return false
      }

      if (!nameValid) {
        this.$root.showMessage('Target group name should be no longer 45 characters.', 'error')
        return false
      }

      if (data.id && data.id.startsWith('_')) {
        delete data.id
        createParticipantGroup(data).then((response) => {
          this.selectedParticipantGroup = response.data
          this.isEditable = false
          this.getParticipantGroupsList()
        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      } else {
        this.$refs.orgLink.moveToOrg((increaseVersion) => {
          if (increaseVersion) data.version++
          updateParticipantGroup(data).then((response) => {
            this.selectedParticipantGroup = response.data
            this.isEditable = false
            this.getParticipantGroupsList()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        })
      }
    },
    deleteParticipantGroup() {
      this.deleteParticipantGroupDialog = false
      if (this.selectedParticipantGroup.id.startsWith('_')) {
        this.selectedParticipantGroup = {
          id: null,
          name: '',
          description: '',
          callGroups: []
        }
      } else {
        deleteParticipantGroup(this.selectedParticipantGroup.id).then(() => {
          this.selectedParticipantGroup = {
            id: null,
            name: '',
            description: ''
          }
          this.getParticipantGroupsList()
        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ?? 'Error deleting participant group'), 'error')
        })
      }
    },
    createCallGroup() {
      this.selectedParticipantGroup.callGroups = Array.isArray(this.selectedParticipantGroup.callGroups) ? this.selectedParticipantGroup.callGroups : []
      this.newCallGroup.id = '_' + this.selectedParticipantGroup.callGroups.length
      this.newCallGroup.orgId = this.selectedParticipantGroup.orgId
      this.newCallGroup.orgName = this.selectedParticipantGroup.orgName
      this.newCallGroup.participantGroupId = this.selectedParticipantGroup.id
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedParticipantGroup.callGroups ? this.selectedParticipantGroup.callGroups.length : 0
      if (!this.newCallGroup.name) {
        this.newCallGroup.name = ''
      }

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedParticipantGroup.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    createParticipantGroup() {
      this.newParticipantGroup.errors = []
      if (!this.newParticipantGroup.orgId) {
        this.newParticipantGroup.orgId = localStorage.getItem('orgId');
      }

      if (!this.newParticipantGroup.name) {
        this.newParticipantGroup.errors.push('Name: may not be null.')
        return false;
      }
      if (this.newParticipantGroup.name.length < 2) {
        this.newParticipantGroup.errors.push('Name: Length is less than allowable minimum of 2.')
        return false;
      }

      this.newParticipantGroupDialog = false
      this.newParticipantGroup.callGroups = []

      this.selectedParticipantGroup = this.newParticipantGroup
      this.selectedParticipantGroup.id = '_'
      this.isEditable = true

    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getParticipantGroups()
        }
      }, 100);
    },
    getLinkedAlarmPlans() {
      getParticipantGroupAlarmPlans(this.selectedParticipantGroup.id).then((response) => {
        this.alarmPlans = response.data.list
      })
    },
    toAlarmPlan(id) {
      this.$router.push(`/alarm-plans/${id}`)
    },
    handleCheckRow(name) {
      const foundedValue = this.alarmParticipantGroupsValues.find(({value}) => value === name)
      return !!foundedValue?.checked?.length;
    },
  }
}
</script>

<style lang="scss" scoped>
.text {
  font-size: 12px;
  padding: 0;
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;

      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    padding: 10px;
  }

}

.p-panel > .p-panel-header {
  padding: 10px !important;
}

.p-inputtext {
  margin: 5px;
}

</style>
